<template>
  <div class="category">
    <div class="item enterprise" @click="register('enterprise')">
      {{ autoPrefix('9cc4fed') }}
    </div>
    <div class="item personal" @click="register('personal')">
      {{ autoPrefix('22d91aa') }}
    </div>
    <div class="item organization" @click="register('organization')">
      {{ autoPrefix('6d0524e') }}
    </div>
    <div class="item lawyer" @click="register('lawyer')">
      {{ autoPrefix('b0d5250') }}
    </div>
    <div class="item lawyer" @click="goAbroadRegister">
      {{ abroad ? '返回注册类型' : 'foreign guest registered' }}
    </div>
    <div class="des">
      <div>{{ autoPrefix('01191c5') }}？</div>
      <div class="uiText" @click="goLogin">{{ autoPrefix('1573214') }}</div>
    </div>
  </div>
</template>

<script>
import i18n from '@/i18n/'
export default {
  name: 'Category',
  data() {
    return {
      // abroad: this.$route.query.abroad === 'abroad',
    }
  },
  computed: {
    abroad() {
      return this.$route.query.abroad === 'abroad'
    },
  },
  methods: {
    goLogin() {
      this.$router.push({
        path: '/account/login',
      })
    },
    register(type) {
      const query = {}
      if (this.abroad) query.abroad = 'abroad'
      this.$router.push({
        path: `/account/register/${type}`,
        query,
      })
    },
    autoPrefix(name) {
      let fun = '$t'
      if (this.abroad) {
        fun = 'geEn'
      }
      return this[fun](name)
    },
    geEn(key) {
      const i18n = this.$i18n
      return i18n._getMessages().en[key] || key
    },
    goAbroadRegister() {
      if (this.abroad) {
        this.$router.replace({
          path: `/account/register`,
          // query: {
          //   abroad: 'abroad',
          // },
        })
      } else {
        this.$router.replace({
          path: `/account/register`,
          query: {
            abroad: 'abroad',
          },
        })
      }
    },
  },
}
</script>

<style scoped lang="less">
@import '~@/styles/variables.less';
// @import '~@/styles/common.less';
.category {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  padding: 60px 0;

  .item {
    position: relative;
    width: 700px;
    height: 98px;
    margin-bottom: 40px;
    background: #fff;
    box-shadow: 0px 0px 34px 4px #f5f6f7;
    border-radius: 8px;
    font-size: 24px;
    font-weight: 500;
    text-align: center;
    line-height: 98px;
    color: #374046;
    cursor: pointer;
    &:hover {
      color: @uni-text-color-select;
      background: #f5fbff;
    }
    &::before {
      position: absolute;
      left: 20px;
      bottom: 0;
      content: '';
      width: 126px;
      height: 88px;
    }
  }

  .enterprise::before {
    background: url('~@/assets/account/enterprise.png');
    background-size: 126px 88px;
  }

  .personal::before {
    height: 88px;
    background: url('~@/assets/account/personal.png');
    background-size: 126px 88px;
  }

  .organization::before {
    background: url('~@/assets/account/organization.png');
    background-size: 126px 88px;
  }

  .lawyer::before {
    background: url('~@/assets/account/lawyer.png');
    background-size: 126px 88px;
  }

  .des {
    display: flex;
    font-size: 14px;
    color: #8f8f8f;
    .uiText {
      color: @uni-text-color-select;
      cursor: pointer;
    }
  }
}
</style>

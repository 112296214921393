<template>
  <div class="register">
    <Title :title="$t(title)" />
    <Category @changeRegisterType="changeRegisterType" />
  </div>
</template>

<script>
import Category from './components/Category'
import Title from '@/components/Title'
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'Home',
  components: {
    Title,
    Category,
  },
  data() {
    return {
      title: '4c50f3d',
      registerType: 'Category',
      stepsActive: 2,
    }
  },
  computed: {
    ...mapGetters(['test']),
    shwoSteps() {
      const type = this.registerType
      return type !== 'Category' && type !== 'personal'
    },
  },
  created() {
    this.handleregisterType()
  },
  methods: {
    ...mapActions(['getT estData']),
    handleregisterType() {},
    changeStepsActive(index) {
      this.stepsActive = index
    },
    changeRegisterType(type) {
      // const obj = {
      //   company: '1',
      //   personal: '1',
      //   mechanism: '1',
      //   lawyer: '1',
      // }
      this.registerType = type
      this.title = type
      console.log('this.$route', this.$route)
    },
  },
}
</script>
<style scoped lang="less">
@import '~@/styles/variables.less';
.register {
  width: @uni-width;
  min-height: calc(100vh - 200px);
  margin: 20px auto;
  background: #fff;
}
</style>
